/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect } from 'react';
import formsContext from '../../../context/forms/formsContext';
import { Ai } from "../../../config/icons"
import clientsContext from '../../../context/clients/clientsContext';
import moduleContext from '../../../context/modules/moduleContext';
import sectionContext from '../../../context/sections/sectionContext';

export const GESTION = props => {
    const formContext = useContext(formsContext);
    const { forms, obtenerForms, selectFormActual } = formContext;

    const clientContext = useContext(clientsContext);
    const { obtenerClientes, clientes } = clientContext;

    const modulesContext = useContext(moduleContext);
    const { moduloActual } = modulesContext;

    const sectionsContext = useContext(sectionContext);
    const {obtenerSecciones} = sectionsContext;

    useEffect(()=>{
        obtenerForms();
        obtenerClientes();
    }, [])

    const getClient = client => {
        const c = clientes.filter(cliente => cliente._id === client)
        if(c.length > 0){
            return(c[0].nombre)
        }
    }

    const editForm = form =>{
        moduloActual("616b4ebf7d9e48349cf3b259")
        obtenerSecciones("616b4ebf7d9e48349cf3b259")
        selectFormActual(form)
    }

    const printForm = () =>{
        console.log("print")
    }
    
    return (
        <div className="contenedor-template">
            <div className="contenedor-propiedad">
                <table className="table-forms">
                    <tr className="head-table-forms">
                        <th>No</th>
                        <th>Tipo de Inmueble</th>
                        <th>Cliente</th>
                        <th>editar</th>
                        <th>imprimir</th>
                    </tr>
                    {forms ? 
                        forms.map((form, i) => {
                            return (
                                <tr key={i}>
                                    <td className="center"><p>{i+1}</p></td>
                                    <td><p>{form.tipoInmueble}</p></td>
                                    <td><p>{getClient(form.cliente)}</p></td>
                                    <td className="center">
                                        <button onClick={() => editForm(form)} type="button" className="btn btn-blank">
                                            <Ai name="AiFillEdit" />
                                        </button>
                                    </td>
                                    <td className="center">
                                        <button onClick={printForm} type="button" className="btn btn-blank">
                                            <Ai name="AiOutlinePrinter"/>
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    :null}
                </table>
            </div>
        </div>
    )
}

export default {
    nombre: "Gestion",
    template: GESTION
};