/* eslint-disable import/no-anonymous-default-export */
import { 
    GUARDAR_IMAGEN, OBTENER_IMAGENES
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case GUARDAR_IMAGEN:
            return {
                ...state,
                    file: action.payload
            }
        case OBTENER_IMAGENES:
            return {
                ...state,
                listado: action.payload
            }
        default:
            return state
    }
}