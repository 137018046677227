import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import React, {useContext, useEffect} from 'react';
import moduleContext from '../../context/modules/moduleContext';
import { 
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    Box,
    Stack,
    Grid
} from '@mui/material';
import Icon from '../layout/Material/Icons'

import imgBkgLogo from '../../img/RL-banner-white.png'
import SelectAutoWidth from './Material/Select';
import { Boton_Modulo, Separador_Modulo, Texto_Modulo } from '../Material';

const drawerWidth = 200;
const openedMixin = (theme) => ({
    width: drawerWidth,
    boxShadow: "-10px 0px 15px 0px black",
    background: 'white',
    color: 'black',
    flexShrink: 0,
    borderStyle: 'solid !important',
    borderWidth: 'thin !important',
    border: '0px !important',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
}),
overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    boxShadow: "-10px 0px 15px 0px black",
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  background: 'white',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const DrawerMui = ({setOpen, open}) => {
    const modulesContext = useContext(moduleContext);
    const { modules, obtenerModulos, moduloActual } = modulesContext;

    // Obtener proyectos cuando carga el componente.
    useEffect(()=>{
        obtenerModulos();
        //eslint.disable-next-line
    }, []);

    const general = modules.filter(module => module.type === 'general')
    const customers = modules.filter(module => module.type === 'customers')
    const eventos = modules.filter(module => module.type === 'eventos')

    const seleccionarModulo = async id => {
        moduloActual(id) //Fijar modulo actual
        // obtenerSecciones(id) //Filtrar secciones
        // selectOption(null);
    }

    const theme = useTheme();
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return(
        <Drawer variant="permanent" open={open}>
            <DrawerHeader 
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                      width: drawerWidth,
                      boxSizing: 'border-box'
                    },
                }}
            style={{
                display: !open ? 'none' : 'block',
            }}>
                <Stack sx={{height: "85px"}}>
                    <Grid container spacing={2} marginBottom={"20px"} sx={{
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center"
                    }}>
                        <Grid style={{display: "grid"}} item xs={12}>
                            <img src={imgBkgLogo} style={{width: '40%', paddingTop: '10px', justifySelf: "center"}}/>
                        </Grid>
                    </Grid>
                </Stack>
            </DrawerHeader>
            <Box style={{paddingTop: 10}}>
                <Separador_Modulo sx={{display: open ? 'block' : 'none'}}>General</Separador_Modulo>
                <List disablePadding>
                    {general.map((module, index) => (
                        <ListItem key={module.name} disablePadding sx={{ display: 'block' }}>
                            <Boton_Modulo onClick={() => seleccionarModulo(module._id)}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: 'black !important'
                                    }}
                                >
                                    <Icon name={module.icon ? module.icon : 'Mail'} />
                                </ListItemIcon>
                                <Texto_Modulo>{module.name}</Texto_Modulo>
                            </Boton_Modulo>
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Box>
                <Separador_Modulo sx={{display: open ? 'block' : 'none'}}>Administración</Separador_Modulo>
                <List disablePadding>
                    {eventos.map((module, index) => (
                        <ListItem key={module.name} disablePadding sx={{ display: 'block' }}>
                            <Boton_Modulo onClick={() => seleccionarModulo(module._id)}>
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                        color: 'black !important'
                                    }}
                                >
                                    <Icon name={module.icon ? module.icon : 'Mail'} />
                                </ListItemIcon>
                                <Texto_Modulo>{module.name}</Texto_Modulo>
                            </Boton_Modulo>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Drawer>
    )
}

export default DrawerMui