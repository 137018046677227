import React, { useContext, useEffect, useState } from 'react';
import PropertyContext from '../../../context/properties/propertyContext';

const NewItem = ({zona, items, view, actionElements, actionFields}) => {
    const propertiesContext = useContext(PropertyContext);
    const { properties } = propertiesContext;

    const {fields, saveField} = actionFields;
    const {elemento, guardarElemento} = actionElements;
    useEffect(()=>{
        saveField({
            ...fields,
            items: elemento
        })
    },[elemento])

    const [itms, saveItem] = useState({
        name: "",
        itm:{}
    });

    const saveNewItem = async () => {
        const keys = Object.keys(itms.itm);

        if(properties.length > keys.length){
            console.log(properties.length, keys.length)
            console.log("faltan de llenar campos")
            return;
        }

        for(let key of keys){
            if(itms.itm[key].trim() === ""){
                console.log(`El campo ${key} es obligatorio`)
                return;
            }
        }

        guardarElemento([
            ...elemento,
            {zona: zona,
            name: `${itms.itm["name-item"]}-${itms.itm["Ubicación"]}`,
            data: itms.itm}
        ])
        
        view(false)
    }

    const handleChange = async e => {
        saveItem({
            ...itms,
            itm:{
                ...itms.itm,
                [e.target.name]: e.target.value
            }             
        })
    }

    const onClick = () => {
        view(false)
    }

    return (
        <form className="list-item-conetenedor" id="new-item">
            <div className="row-template" >
                <div className="col-1-6 right" >
                    <label>Item:</label>
                </div>
                <div className="col-1-8">
                    <select 
                        className="type-new-item"
                        onChange={handleChange}
                        name="name-item"
                        value={itms.itm["name-item"]}>
                            <option selected>Seleccione una opción...</option>
                            {items.map((item, i) => {
                                if(item.asignToZone === zona.id){
                                    return(
                                        <option value={item.nombre}>{item.nombre}</option>
                                    )
                                }
                            })}
                    </select>    
                </div>
            </div>
            <div>
                {properties ? 
                    properties.map((property, i) => (
                        <div className="row-template" key={i}>
                            <div className="col-1-6 right" key={i}>
                                <label>{property.nombre}:</label>
                            </div>
                            <div className="col-2">
                                {property.type === "String" ? 
                                    <input 
                                        type="text"
                                        placeholder={property.nombre}
                                        value={itms.itm[property.nombre]}
                                        onChange={handleChange}
                                        name={property.nombre}
                                        id={property.nombre}
                                    />: null}
                                {property.type === "File" ?
                                    <div className="file-new-item">
                                        <input
                                            type="file"
                                            placeholder={property.nombre}
                                            value={itms.itm[property.nombre]}
                                            onChange={handleChange}
                                            name={property.nombre}
                                        />
                                    </div>
                                    : null}
                                {property.type === "Multiple" ? 
                                    <select
                                        className="type-new-item"
                                        value={itms.itm[property.nombre]}
                                        onChange={handleChange}
                                        name={property.nombre}>
                                            <option value="select">Seleccionar...</option>
                                            {property.answers.map((answer, i) => (
                                                <option value={answer.status}>{answer.text}</option>
                                            ))}
                                    </select>
                                : null}
                            </div>
                        </div>
                    )) 
                : null}
                <div className="right">
                    <button className="submit-new-item" type="button" onClick={onClick}>Cancel</button>
                    <button className="submit-new-item" type="button" onClick={saveNewItem}>Guardar Item</button>
                </div>
            </div>
        </form>
    )    
}

export default NewItem;