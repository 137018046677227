import { Box, Grid, Typography, styled } from '@mui/material';
import guestsContext from '../../context/guests/guestsContext';
import { useContext, useEffect, useState } from 'react';
import eventosContext from '../../context/eventos/eventosContext';
import AuthContext from '../../context/authentication/authContext';
import { PieChart } from '@mui/x-charts';

const Dashboard = () => {
    const [asistencias, setAsistencias]     = useState(0)
    const [ausencias,   setAusencias]       = useState(0)
    const [pendientes,  setPendientes]      = useState(0)
    const [total,       setTotal]           = useState(0)
    const [totalInvitaciones, setTotalInv]  = useState(0)
    const [invConf, setInvConf]             = useState(0)
    const [invNoConf, setInvNoConf]         = useState(0)
    const [invPend, setInvPend]             = useState(0)
    

    const authsContext = useContext(AuthContext);
    const { usuario } = authsContext;

    const invitadoContext = useContext(guestsContext);
    const {invitados, obtenerInvitados} = invitadoContext;

    const EventosContext = useContext(eventosContext);
    const { evento } = EventosContext;

    useEffect(()=>{
        if(evento && usuario?._id){
            obtenerInvitados({userId: usuario._id, idEvento: evento});
        }
    },[evento])

    useEffect(()=>{
        if(invitados?.length > 0){
            let num_invitados = 0
            let num_ausencias = 0
            let num_pendientes = 0
            let num_total = 0
            let num_total_inv_conf      = 0
            let num_total_inv_no_conf   = 0
            let num_total_inv_pend      = 0
            
            invitados.map(invitado=>{
                num_total = num_total + invitado.reserved
                if(invitado.confirm != 1010){
                    if(invitado.confirm > 0){
                        num_invitados = num_invitados + invitado.confirm
                        let num_dif_ausencias = invitado.reserved - invitado.confirm
                        num_ausencias = num_ausencias + num_dif_ausencias
                        num_total_inv_conf = num_total_inv_conf + 1
                    }else{
                        num_pendientes = num_pendientes + invitado.reserved
                        num_total_inv_pend = num_total_inv_pend + 1
                    }
                }else{
                    num_ausencias = num_ausencias + invitado.reserved
                    num_total_inv_no_conf = num_total_inv_no_conf + 1
                }
            })
            
            setAsistencias(num_invitados)
            setAusencias(num_ausencias)
            setPendientes(num_pendientes)
            setTotal(num_total)
            setTotalInv(invitados.length)

            setInvConf(num_total_inv_conf)
            setInvNoConf(num_total_inv_no_conf)
            setInvPend(num_total_inv_pend)
        }
    },[invitados])

    const [dataseries, setDataseries] = useState([
        { label: 'Confirmados', value: 0 },
        { label: 'Rechazadas',  value: 0 },
        { label: 'Pendientes',  value: 0 },
    ]);

    const [dataseries_, setDataseries_] = useState([
        { label: 'Confirmados', value: 0 },
        { label: 'Rechazadas',  value: 0 },
        { label: 'Pendientes',  value: 0 },
    ]);

    useEffect(()=>{
        setDataseries([
            { label: 'Confirmados', value: asistencias },
            { label: 'Rechazadas',   value: ausencias },
            { label: 'Pendientes',  value: pendientes},
        ])
    },[asistencias, ausencias, pendientes])

    useEffect(()=>{
        setDataseries_([
            { label: 'Confirmadas', value: invConf },
            { label: 'Rechazadas',  value: invNoConf },
            { label: 'Pendientes',  value: invPend },
        ])
    },[invConf, invNoConf, invPend])

    return(<Box sx={{margin: '20px'}}>
    <Box sx={{paddingTop: "10px"}}>
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <Box>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{
                                background: "white",
                                height: "110px",
                                paddingTop: "5px",
                                width: "100%",
                                borderRadius: "15px",
                                boxShadow: "0px 4px 18px -7px black"
                            }}>
                                <Typography sx={{
                                    fontWeight:"bold",
                                    fontSize: "16px",
                                    margin: '10px 20px',
                                    color: "#7a7e83",

                                }}>
                                    Total Invitados
                                </Typography>
                                <Typography sx={{
                                    fontWeight:"300",
                                    fontSize: "25px",
                                    // color: "white",
                                    color: "#7a7e83",
                                    margin: '10px 20px'
                                }}>
                                    {total}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{
                    background: "white",
                    height: "225px",
                    borderRadius: "15px",
                    boxShadow: "0px 4px 18px -7px black"
                }}>
                    <Grid container>
                        <Grid item xs={12} sx={{padding: "20px 0px"}}>
                            <Typography variant='h5' sx={{
                                marginLeft: "18px",
                                fontWeight: 400,
                                color: "#7a7e83",
                            }}>INVITADOS</Typography>
                            <PieChart
                                colors={["#6d94b7", "#ad3800ad", "rgb(179 179 179)"]}
                                series={[{data: dataseries, innerRadius: 40, cornerRadius: 5, paddingAngle: 3}]}
                                width={350}
                                height={150}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{
                                background: "white",
                                height: "110px",
                                width: "100%",
                                paddingTop: "5px",
                                borderRadius: "15px",
                                boxShadow: "0px 4px 18px -7px black"
                            }}>
                                <Typography sx={{
                                    fontWeight:"bold",
                                    fontSize: "16pxs",
                                    margin: '10px 20px',
                                    color: "#7a7e83",

                                }}>
                                    Total Invitaciones
                                </Typography>
                                <Typography variant='h4' sx={{
                                    fontWeight:"300",
                                    fontSize: "25px",
                                    color: "#7a7e83",
                                    margin: '10px 20px'
                                }}>
                                    {totalInvitaciones}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{
                    background: "white",
                    height: "225px",
                    borderRadius: "15px",
                    boxShadow: "0px 4px 18px -7px black"
                }}>
                    <Grid container>
                        <Grid item xs={12} sx={{padding: "20px 0px"}}>
                            <Typography variant='h5' sx={{
                                marginLeft: "18px",
                                fontWeight: 400,
                                color: "#7a7e83",
                            }}>INVITACIONES</Typography>
                            <PieChart
                                colors={["#6d94b7", "#ad3800ad", "rgb(179 179 179)"]}
                                series={[{ data: dataseries_, innerRadius: 40, cornerRadius: 5, paddingAngle: 3}]}
                                width={350}
                                height={150}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            {/* <Grid item xs={4}>
                <Box sx={{
                    background: "#f44336",
                    height: "150px",
                    borderRadius: "15px",
                    boxShadow: "0px 4px 18px -7px black"
                }}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant='h5' sx={{fontWeight:"bold",color: "white", margin: '10px 20px'}}>
                                Ausencias
                            </Typography>
                            <Typography variant='h3' sx={{
                                fontWeight:"bold",
                                color: "white",
                                margin: '10px 20px'
                            }}>
                                {ausencias}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{
                    background: "rgb(150 150 150)", 
                    height: "150px", 
                    borderRadius: "15px",
                    boxShadow: "0px 4px 18px -7px black"
                }}>
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant='h5' sx={{fontWeight:"bold",color: "white", margin: '10px 20px'}}>
                                Pendientes
                            </Typography>
                            <Typography variant='h3' sx={{
                                fontWeight:"bold",
                                color: "white",
                                margin: '10px 20px'
                            }}>
                                {pendientes}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                </Box>
            </Grid> */}
        </Grid>
    </Box>
</Box>)
}

export default Dashboard;