import React, { useReducer } from 'react';
import multimediaReducer from './multimediaReducer';
import multimediaContext  from './multimediaContext';
import clientAxios from '../../config/axios';
import { GUARDAR_IMAGEN, OBTENER_IMAGENES } from '../../types'

const MultimediaState = props => {
    const initialState = {
        files: [],
        file: []
    }
    const [state, dispatch] = useReducer(multimediaReducer, initialState)

    const guardarImagen = async (file, name) =>{
        try{
            if(!name) return console.log("No hay nombre")
            var formData = new FormData();
            formData.append("name", name);
            formData.append("file", file[0]);

            const res = await clientAxios({
                method: "post",
                url: "/api/multimedia",
                data: formData,
                headers: { "Content-Type": "multipart/form-data" }
            })

            dispatch({
                type: GUARDAR_IMAGEN, 
                payload: res.result
            })
    
        } catch(err){
            console.log(err)
        }
    }

    const eliminarImagen = async (file, folder, key) =>{
        try{
            await clientAxios.post(`/api/multimedia/eliminar/${file}`, {folder: folder})    
        } catch(err){
            console.log(err)
        }
    }

    const obtenerListadoImagenes = async folder =>{
        try{
            const res = await clientAxios.get(`/api/multimedia/folder/${folder}`)
            let listado = await res.data.result;
            
            dispatch({
                type: OBTENER_IMAGENES, 
                payload: listado
            })
    
        } catch(err){
            console.log(err)
        }
    }

    return(
        <multimediaContext.Provider value={{
            listado: state.listado,
            file: state.file,
            guardarImagen,
            eliminarImagen,
            obtenerListadoImagenes
        }}>
            {props.children}
        </multimediaContext.Provider>
    )
}

export default MultimediaState;
