/* eslint-disable import/no-anonymous-default-export */
import {
    OBTENER_TEMPORAL,
    CREAR_TEMPORAL,
    OBTENER_TEMPORAL_ID_EVENTO
} from '../../types'

export default (state, action) => {
    switch(action.type){
        case OBTENER_TEMPORAL:
            return {
                ...state,
                invitados: action.payload 
            }
        case CREAR_TEMPORAL:
            console.log(action.payload)
            return {
                ...state
            }
        case OBTENER_TEMPORAL_ID_EVENTO:
            console.log(action.payload)
            return {
                ...state,
                idEvento: action.payload
            }
        default:
            return state;
    }
}