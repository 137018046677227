import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import React, { useContext, useEffect } from 'react';
import AuthContext from '../../context/authentication/authContext';
import moduleContext from '../../context/modules/moduleContext';
import ReactLoading from 'react-loading'
import Template from '../templates'
import {
    Box,
    CssBaseline,
    Grid,
    Typography
} from '@mui/material';
import ToolbarMui from '../layout/Toolbar';
import DrawerMui from '../layout/Drawer';
import Dashboard from '../general/Dashboard';

const drawerMaxWidth = 60;
const drawerMinWidth = 200;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: (open ? drawerMinWidth : drawerMaxWidth),
        width: `calc(100% - ${(open ? drawerMinWidth : drawerMaxWidth)}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Modules = () => {
    const modulesContext = useContext(moduleContext);
    const { module } = modulesContext;
    // Extraer la info de autentificación.
    const authToken = useContext(AuthContext);
    const { usuarioAutenticado, usuario } = authToken;
    
    const [open, setOpen] = React.useState(true);

    useEffect(()=>{
        usuarioAutenticado();
    }, [])
    
    if(usuario !== null){
        return(
            <div className="contenedor-app">
            
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position='fixed' style={{boxShadow: 'none'}} open={open} 
                    sx={{
                        width: { sm: `calc(100% - ${(open ? drawerMinWidth : drawerMaxWidth)}px)` },
                        ml: { sm: `${(open ? drawerMinWidth : drawerMaxWidth)}px` },
                }}>
                    <ToolbarMui position="fixed" setOpen={setOpen} open={open}/>
                </AppBar>
                <DrawerMui setOpen={setOpen} open={open}/>
                <Box component="main" 
                    sx={{ 
                        flexGrow: 1,
                        p: 3, 
                        padding: 0,
                        height: '100vh',
                        width: { 
                            sm: `calc(100% - ${(open ? drawerMinWidth : drawerMaxWidth)}px)` 
                        }                         
                    }}

                    className="templateBackground"
                >
                    <DrawerHeader sx={{minHeight: "30px !important"}}/>
                    {module ? (
                       <Template nombre={module[0].name} />
                    ):(<Dashboard/>)}
                </Box>
            </Box>

        </div>
    )
    }else {
        return(
            <div id="loader-div" style={{
                paddingTop: '35vh',
                width: '100%',
                textAlign: '-webkit-center'
            }}>
                <ReactLoading 
                    type='balls' 
                    color='white' 
                    width={'10%'}
                />
            </div>
        )
    }
}

export default Modules;