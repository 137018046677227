import React, { useReducer } from 'react';
import guestsReducer from './guestsReducer';
import GuestsContext  from './guestsContext';
import clientAxios from '../../config/axios';
import { 
    ELIMINAR_INVITADO,
    GUARDAR_INVITADO,
    OBTENER_INVITADOS
} from '../../types'

const GuestsState = props => {
    const initialState = {
        invitado: [],
        invitados: [],
    }
    const [state, dispatch] = useReducer(guestsReducer, initialState)

    const guardarInvitado = async invitado => {
        try{
            const res = await clientAxios.post('/api/guests', invitado);
            if(res.data.success){
                let data = res.data.msg;
                dispatch({
                    type: GUARDAR_INVITADO, 
                    payload: res.data.data
                })
                return {
                    id: data.userId,
                    success: res.data.success
                }
            } else {
                return res.data
            }
        }catch(err){
            console.log(err)
        }
    }

    const eliminarInvitado = async id => {
        try{
            const data = state.invitados;
            const dataFilter = await data.filter(invitado => invitado._id !== id)

            const res = await clientAxios.post('/api/guests/delete', {_id: id});
            if(res.data.success){
                let data = res.data.msg;
                dispatch({
                    type: ELIMINAR_INVITADO, 
                    payload: dataFilter
                })
                return {
                    id: data.userId,
                    success: res.data.success
                }
            } else {
                return res.data
            }
        }catch(err){
            console.log(err)
        }
    }

    const obtenerInvitados = async userId => {
        try{
            const res = await clientAxios.post('/api/guests/getByEvent', userId)
            dispatch({
                type: OBTENER_INVITADOS,
                payload: res.data.result
            })
        } catch(err){
            console.log(err)
        }
    }

    const modificarInvitado = async data => {
        try{
            let res = await clientAxios.post('/api/guests/update', data)
            if(res?.data?.code === 200){
                window.location.reload();
            }
        } catch(err){
            console.log(err)
        }
    }

    const modificarInvitados = async data => {
        try{
            let res = await clientAxios.post('/api/guests/updatemasivos', data)
            console.log(res)
            // if(res?.data?.code == 200){
            //     window.location.reload();
            // }
        } catch(err){
            console.log(err)
        }
    }
    
    return(
        <GuestsContext.Provider value={{
            invitado: state.invitado,
            invitados: state.invitados,
            guardarInvitado,
            eliminarInvitado,
            obtenerInvitados,
            modificarInvitado,
            modificarInvitados
        }}>
            {props.children}
        </GuestsContext.Provider>
    )
}

export default GuestsState;
