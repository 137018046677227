/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from 'react';
import { Bloque, Titulo } from "../../Material";
import { Box, Grid, styled } from '@mui/material';
import eventosContext from '../../../context/eventos/eventosContext';
import { BotonGuardar } from '../../../elements';

const CONFIGURACIONES = props => {
    const EventosContext = useContext(eventosContext);
    const { modificarEvento, evento, eventos } = EventosContext;
    const [form, setForm] = useState({})
    const [first, setFirst] = useState({})
    const [update, setUpdate] = useState(false)

    function definitiveComparasion( obj1, obj2 ){
        let keys1 = Object.keys(obj1);
        let keys2 = Object.keys(obj2);

        if ( keys1.length !== keys2.length ) return false;

        // let array_map = keys1.map(key1 => {
        //     let val1 = obj1[key1];
        //     let val2 = obj2[key1];
    
        //     if ( val1 !== val2 ) return false;
        // })

        for(let key__ of keys1) {
            let val1 = obj1[key__];
            let val2 = obj2[key__];

            if ( val1 !== val2 ) return false;
        }

        return true;
    }

    useEffect(()=>{
        if(evento || evento != ""){
            let data_evento = eventos.filter(ev => ev._id == evento)
            if(data_evento?.length > 0){
                let res_filter = data_evento[0]
                setForm({
                    ...form,
                    ...res_filter,
                    hashtag: res_filter?.hashtag ? res_filter.hashtag: "",
                    n_cuenta: res_filter?.n_cuenta ? res_filter.n_cuenta: ""
                })

                setFirst({
                    ...form,
                    ...res_filter,
                    hashtag: res_filter?.hashtag ? res_filter.hashtag: "",
                    n_cuenta: res_filter?.n_cuenta ? res_filter.n_cuenta: ""
                })
            }
        }
    },[evento])

    useEffect(()=>{
        let res_comparative = definitiveComparasion(form, first)
        setUpdate(!res_comparative)
    },[form])

    const guardar_evento = () => {
        modificarEvento({_id: evento, ...form})
        setUpdate(false)
        setFirst(form)
    }

    return (<Bloque className="scrollBloque">
        <Grid container>
            <Grid item xs={8}>
                <Titulo>Configuraciones</Titulo>
            </Grid>
            <Grid item xs={4}>
                {update ? (<Box sx={{display: "grid", alignContent: "start", justifyContent: "end"}}>
                    <BotonGuardar sx={{fontSize: "12px"}} onClick={guardar_evento}>Guardar</BotonGuardar>
                </Box>): null}
            </Grid>
        </Grid>
        <Grid spacing={2} container sx={{
            margin: "10px 0px",
            width: "100%"
        }}>
            <Grid item xs={5}>
                <Texto>Número de cuenta</Texto>
                <Campo
                    id="numero_cuenta"
                    name="numero_cuenta"
                    value={form.n_cuenta}
                    onChange={e=>setForm({
                        ...form,
                        n_cuenta: e.target.value
                    })}
                />
                <Texto sx={{marginTop: "10px"}}>#Hashtag</Texto>
                <Campo
                    id="hashtag"
                    name="hashtag"
                    value={form.hashtag}
                    onChange={e=>setForm({
                        ...form,
                        hashtag: e.target.value
                    })}
                />
            </Grid>
            <Grid item xs={2}>

            </Grid>
            <Grid item xs={5}>
                <Texto>Plantilla de la invitación</Texto>
                <Select onChange={e=>{
                    setForm({
                        ...form,
                        id_plantilla: parseInt(e.target.value)
                    })
                }}>
                   <Option selected={form.id_plantilla == 0 ? true: false} value={0}>Seleccionar Plantilla</Option> 
                   <Option selected={form.id_plantilla == 1 ? true: false} value={1}>jaqmik</Option> 
                   <Option selected={form.id_plantilla == 2 ? true: false} value={2}>adkar</Option> 
                   <Option selected={form.id_plantilla == 3 ? true: false} value={3}>jeimi</Option>
                   <Option selected={form.id_plantilla == 4 ? true: false} value={4}>katherine</Option>
                </Select>
            </Grid>
        </Grid>
    </Bloque>)
}

const Select = styled('select')({
    borderRadius: "8px",
    width: "100%",
    fontSize: "12px",
    padding: "5px 10px",
    cursor: "pointer"
})

const Option = styled('option')({
    padding: "10px !important",
    margin: "10px !important",
})


const Campo = styled('input')({
    width: "100%",
    padding: "7px 10px",
    fontSize: "12px",
    borderRadius: "5px"
})

const Texto = styled('p')({
    margin: "0px",
    fontSize: "12px"
})

const BotonPersonalizado = styled('button')({
    background: "#403000 !important",
    color: "rgb(255, 255, 255) !important",
    textTransform: "none !important",
    cursor: "pointer",
    border: "none",
    borderRadius: "5px",
    padding: "7px 12px",
    outline: "none !important",
    fontSize: "12px",
    "&:hover":{
        backgroundColor: "#d7b615 !important",
        color: "white !important",
        textShadow: "0px 5px 10px #ffeb77"
    }
})

export default {
    nombre: "Configuraciones",
    template: CONFIGURACIONES
};