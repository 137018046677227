import React, { useReducer } from 'react';
import moduleContext from './moduleContext';
import moduleReducer from './moduleReducer';
import clientAxios from '../../config/axios'
import { 
    FORMULARIO_MODULO,
    AGREGAR_MODULO,
    VALIDAR_FORMULARIO,
    MODULO_ACTUAL,
    ELIMINAR_MODULO
} from '../../types'

const ModuleState = props => {
    const initialState = {
        modules:[
            { 
                _id: '616b4ebf7d9e48349cf3b259', 
                name:'Dashboard', 
                type:'general',
                icon: 'Dashboard'
            },
            {
                _id: '616b4ebf7d9e48349cf3b263',
                name:'Invitaciones',
                type: 'eventos',
                icon:'Conversations'
            },
            {
                _id: '616b4ebf7d9e48349cf3b265',
                name:'Solicitudes',
                type: 'eventos',
                icon:'Solicitudes'
            },
            {
                _id: '616b4ebf7d9e48349cf3b270',
                name:'Eventos',
                type: 'eventos',
                icon:'Eventos'
            },
            {
                _id: '616b4ebf7d9e48349cf3b262',
                name:'Multimedia',
                type: 'eventos',
                icon:'Multimedia'
            },{
                _id: '123b4ebf7d9e48349cf3b263',
                name:'Apartados',
                type: 'eventos',
                icon:'Apartados'
            },{
                _id: '123b4ebf7d9e48349cf3b265',
                name:'Pases',
                type: 'eventos',
                icon:'Pases'
            },{
                _id: '123b4ebf7d9e48349cf3b264',
                name:'Configuraciones',
                type: 'eventos',
                icon:'Configuraciones'
            }
        ],
        formulario: false,
        errorFormulario: false,
        module: null
    }

    const [state, dispatch] = useReducer(moduleReducer, initialState)

    const mostrarFormulario = () => {
        dispatch({
            type: FORMULARIO_MODULO
        })
    }

    const obtenerModulos = async ()  => {
        try{
            console.log("modulos")
        } catch(err){
            console.log(err)
        }
    }

    const agregarModulo = async modulo => {
        try{
            const res = await clientAxios.post('/api/modulos', modulo)

            dispatch({
                type: AGREGAR_MODULO,
                payload: res.data
            })
        }catch(err){
            console.log(err)
        }
    }

    const mostrarError = () => {
        dispatch({
            type: VALIDAR_FORMULARIO
        })
    }

    // selecciona el modulo en el que el usuario dio clic
    const moduloActual = id => {
        dispatch({
            type: MODULO_ACTUAL,
            payload: id
        })
    }

    const eliminarModulo = async id =>{
        try{
            await clientAxios.delete(`http://localhost:4000/api/modulos/${id}`)
            dispatch({
                type: ELIMINAR_MODULO,
                payload: id
            })
        }catch(err){
            console.log(err)
        }
    } 

    return(
        <moduleContext.Provider
            value={{
                modules: state.modules,
                formulario: state.formulario,
                errorFormulario: state.errorFormulario,
                module: state.module,
                mostrarFormulario,
                obtenerModulos,
                agregarModulo,
                mostrarError,
                moduloActual,
                eliminarModulo
            }}
        >
            {props.children}
        </moduleContext.Provider>
    )
}

export default ModuleState 