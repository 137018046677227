/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import Dashboard from '../../general/Dashboard';

const DASHBOARD = () => {
    return  (<Dashboard/>)
    
}

export default {
    nombre: "Dashboard",
    template: DASHBOARD
};