/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import Apartados from '../../apartados';

const APARTADOS = () => {
    return  (<Apartados/>)
    
}

export default {
    nombre: "Apartados",
    template: APARTADOS
};