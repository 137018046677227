/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from 'react';
import GoogleMaps from '../../../config/GoogleMaps';
import formItemsContext from '../../../context/formItems/formItemsContext';
import clientsContext from '../../../context/clients/clientsContext';
import inspectorsContext from '../../../context/inspectors/inspectorsContext';
import zonaContext from '../../../context/zonas/zonaContext';
import Item from '../../layout/Items/Item'
import formsContext from '../../../context/forms/formsContext';
import AuthContext from '../../../context/authentication/authContext';
import PropertyContext from '../../../context/properties/propertyContext';

export const FORMULARIO = props => {
    let client, inspector = {};
    const zonasContext = useContext(zonaContext);
    const { obtenerZonas, zonas } = zonasContext;

    const clientContext = useContext(clientsContext);
    const { guardarCliente, obtenerClientes, clientes } = clientContext;

    const authContext = useContext(AuthContext);
    const { usuario, usuarioAutenticado } = authContext;

    const formContext = useContext(formsContext);
    const { guardarForm } = formContext;

    const propertiesContext = useContext(PropertyContext);
    const { obtenerPropiedades } = propertiesContext;
    
    useEffect(()=>{
        obtenerZonas()
        obtenerClientes()
        usuarioAutenticado()
        obtenerPropiedades()
    }, []);

    const [fields, saveField] = useState({});

    const onSubmit = async e =>{
        if(typeof fields.numeroCliente==='undefined' || fields.numeroCliente==="NaN"){
            if(fields.customerName         && 
                fields.customerAddress     && 
                fields.customerSuburb      && 
                fields.customerPostalCode  && 
                fields.customerCity){
                client = await guardarCliente({
                        nombre: fields.customerName,
                        direccion: fields.customerAddress,
                        colonia: fields.customerSuburb,
                        ciudad: fields.customerCity,
                        estado: fields.customerCountry,
                        codigoPostal: fields.customerPostalCode
                    })
                fields.numeroCliente = client.id
            }else{
                console.log("Los datos del cliente son obligatorios")
            }
        }

        if(await usuario){
            inspector["id"] = `${usuario._id}`
            fields.numeroInspector = inspector.id
        }

        const newForm = await guardarForm({
            pisos: fields.formsFloors,
            archivos: fields.formsAdjuntfiles,
            construccion: fields.formsConstruction,
            tipoInmueble: fields.formsTypeInm,
            archivos: fields.inspectorFiles,
            cliente: fields.numeroCliente,
            inspector: fields.numeroInspector,
            items: fields.items ? fields.items : []
        })            

        console.log(fields)
    } 

    const handleChange = e => {
        saveField({
            ...fields,
            [e.target.name]: e.target.value             
        })
    }


    return (
        <div className="contenedor-template">
            <form onSubmit={onSubmit} id="new-form">
                <div className="form-template bordes-contenedor">
                    <h3>Resumen de inspección</h3>
                    <div className="row-template">
                        <div className="col-2">
                            <div className="center">
                                <h4>Datos del cliente</h4>
                            </div>
                            <div className="cell">
                                <p>Cliente:</p>
                                <select 
                                    id="numeroCliente"
                                    name="numeroCliente"
                                    className="type-new-item"
                                    value={fields["numeroCliente"]}
                                    onChange={handleChange}
                                    >
                                        <option value="NaN" selected>Seleccione...</option>
                                        {clientes.map(client => (
                                            <option value={client._id}>{client.nombre}</option>
                                        ))}
                                </select>
                            </div>
                            <div className="cell">
                                <p>Nombre del cliente:</p>
                                <input type="text"
                                    placeholder="Nombre del Cliente"
                                    value={fields["customerName"]}
                                    onChange={handleChange}
                                    name="customerName"
                                    id="customerName"
                                />
                            </div>
                            <div className="cell">
                                <p>Calle y Número:</p>
                                <input type="text"
                                    id="customerAddress"
                                    name="customerAddress"
                                    placeholder="Calle y número"
                                    value={fields["customerAddress"]}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="cell">
                                <p>Colonia:</p>
                                <input type="text"
                                    id="customerSuburb"
                                    name="customerSuburb"
                                    placeholder="Colonia"
                                    value={fields["customerSuburb"]}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="cell">
                                <p>Ciudad:</p>
                                <input type="text"
                                    id="customerCity"
                                    name="customerCity"
                                    placeholder="Ciudad"
                                    value={fields["customerCity"]}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="cell">
                                <p>Estado:</p>
                                <input type="text"
                                    id="customerCountry"
                                    name="customerCountry"
                                    className="flex-cell"
                                    placeholder="Estado"
                                    value={fields["customerCountry"]}
                                    onChange={handleChange}
                                />
                                <p>C.P:</p>
                                <input type="text"
                                    id="customerPostalCode"
                                    name="customerPostalCode"
                                    className="flex-cell"
                                    placeholder="Código Postal"
                                    value={fields["customerPostalCode"]}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="cell">
                                <p>Tipo de inmueble:</p>
                                <select 
                                    id="formsTypeInm"
                                    name="formsTypeInm"
                                    value={fields["formsTypeInm"]}
                                    className="type-new-item"
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="value0">Seleccione...</option>
                                    <option value="Vivienda">Vivienda</option>
                                    <option value="Comercial">Comercial</option>
                                    <option value="Oficinas">Oficinas</option>
                                    <option value="Institucional">Institucional</option>
                                    <option value="Industrial">Industrial</option>
                                    <option value="SubUrbano">Sub-Urbano</option>
                                    <option value="Otros">Otros</option>
                                </select>
                            </div>
                            <div className="cell">
                                <p>M2 / Construcción:</p>
                                <input type="text"
                                    id="formsConstruction"
                                    name="formsConstruction"
                                    className="flex-cell"
                                    value={fields["formsConstruction"]}
                                    onChange={handleChange}
                                />
                                <p>No. Pisos:</p>
                                <input type="text"
                                    id="formsFloors"
                                    name="formsFloors"
                                    className="flex-cell"
                                    value={fields["formsFloors"]}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="center">
                                <h4>Ubicación:</h4>
                            </div>
                            <div className="contenedor-maps">
                                <GoogleMaps></GoogleMaps>
                            </div>
                        </div>
                    </div>
                    <div className="cell-fix">
                        <h4 className="center">Documentos adjuntos</h4>
                        <div className="cell">
                            <label>Tipo de documento:</label>
                            <select id="inspectorFiles"
                                    name="inspectorFiles"
                                    value={fields["inspectorFiles"]}
                                    className="type-new-item"
                                    onChange={handleChange}
                            >
                                <option value="luz">Recibo de Luz</option>
                                <option value="agua">Recibo de agua</option>
                                <option value="planos">Planos </option>
                                <option value="docsLegales">Documentos legales</option>
                                <option value="otro">Otros</option>
                            </select>
                        </div>
                        <div className="cell">
                            <input type="file"
                                id="formsAdjuntfiles"
                                name="formsAdjuntfiles"
                                className="flex-cell adjunt-file"
                                value={fields["formsAdjuntfiles"]}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="form-template bordes-contenedor">
                    <h3>Inspección del inmueble</h3>
                    <Item zonas={zonas} actionFields={{fields: fields, saveField: saveField}} elementos={[]}/>
                </div>
            </form>
            <div className="form-template center">
                <button className="submit-new-item" type="submit" form="new-form">Guardar Formulario</button>
                <button className="submit-new-item" type="button" form="new-form">Imprimir</button>
            </div>
        </div>
    )
}

export default {
    nombre: "Formulario",
    template: FORMULARIO
};