import React, { useEffect } from 'react';
import { 
    Toolbar, 
    IconButton,
    Typography,
    Grid,
    TextField,
    Box,
    FormControl,
    OutlinedInput,
    Menu,
    Tooltip,
    Avatar,
    MenuItem,
    Divider,
    ListItemIcon, 
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {AccountCircle, SyncAlt, Settings, Logout, Search, PersonAdd} from '@mui/icons-material';
import AuthContext from '../../context/authentication/authContext';
import { useContext } from 'react';
import { Lista, ListaBoton, SelectorEvento, Texto } from '../Material';
import eventosContext from '../../context/eventos/eventosContext';
import guestsContext from '../../context/guests/guestsContext';

const settings = [
    {
        name: 'Profile',
        icon: <AccountCircle/>
    }, {
        name: 'Settings',
        icon: <Settings/>
    }, {
        name: 'Change organization',
        icon: <SyncAlt/>
    }, {
        name: 'Logout',
        icon: <Logout/>
    }];
const ToolbarMui = ({setOpen, open}) => {
    let id_evento = localStorage.getItem("id_evento")
    const authContext = useContext(AuthContext);
    const { usuario, cerrarSesion } = authContext;

    const invitadoContext = useContext(guestsContext);  
    const { obtenerInvitados } = invitadoContext;

    const EventosContext = useContext(eventosContext);
    const { eventos, evento, seleccionarEvento, obtenerEventos } = EventosContext;

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [evento_array, setEvento] = React.useState({});
    const [letterName, setLetterName] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openP = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => { setAnchorEl(null) };

    useEffect(()=>{
        let id_evento = localStorage.getItem("id_evento")
        if(!evento){
            if(id_evento){
                seleccionarEvento(id_evento)
                console.log(id_evento)
            }else{
                if(eventos?.length > 0){
                    seleccionarEvento(eventos[0]._id)
                }else{
                    obtenerEventos();
                }
            }
        }
        obtenerEventos()
    },[])

    useEffect(()=>{
        cambiar_nombre()
    },[eventos])

    useEffect(()=>{
        cambiar_nombre()
    },[evento])

    useEffect(()=>{
        if(usuario?.name){
            let userName = usuario.name
            let firstLetter = userName.charAt(0);
            setLetterName(firstLetter.toUpperCase())
        }else{
            if(usuario?.email){
                let userName = usuario.email
                let firstLetter = userName.charAt(0);
                setLetterName(firstLetter.toUpperCase())
            }else{
                setLetterName("E")
            }
        }
    },[usuario])


    const cambiar_nombre = () => {
        let filtro_evento = eventos.filter(evento__ => evento__._id === id_evento)
        setEvento(filtro_evento)
    }
    return(
    <Toolbar
        id="aaaaaaaaaaaaa"
        className='hrDrawerBottom'
        style={{ 
            margin: "0px",
            padding: "0px 10px 0px 0px",
            boxShadow: 'none',
            background: 'white', 
            color: 'black',
            boxShadow: "black 0px 8px 19px -20px",
    }}>
        <Grid container>
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={9} sx={{display: "grid", justifyContent: "end"}}>
                <SelectorEvento onClick={handleClick}>
                    <Texto sx={{marginRight: "20px"}}>{
                        evento_array.length > 0 ? evento_array[0].nombre: "evento"
                    }</Texto>
                    <IconButton
                        size="small"
                        sx={{ margin: "0px", padding: "0px 2px 0px 0px"}}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{
                            width: 20,
                            height: 20,
                            fontSize: "12px",
                            bgcolor: "#2da6ff",
                            color: "white"
                        }}>{letterName ? letterName: "E"}</Avatar>
                    </IconButton>
                </SelectorEvento>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openP}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        minHeight: "30px !important",
                        minWidth: "220px",
                        padding: "0px",
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                        width: 18,
                        height: 18,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <Texto sx={{
                        margin: "0px",
                        paddingLeft: "20px",
                        paddingBottom: "5px",
                        borderBottom: "1px solid #e1e1e1",
                        fontSize: "13px",
                        fontWeight: "500"
                    }}>Eventos</Texto>
                    <Lista className='scrollStyle'>
                        {eventos?.length ? eventos.map((evento, i_evento) =>(
                            <ListaBoton key={i_evento} onClick={()=>{
                                if(evento?._id){
                                    seleccionarEvento(evento._id)
                                }
                            }}>{evento?.nombre ? evento?.nombre: "Sin Nombre"}</ListaBoton>
                        )): null}
                    </Lista>
                    
                    <MenuItem sx={{fontSize: "12px"}} onClick={handleClose}><Avatar />Perfil</MenuItem>
                    <MenuItem sx={{fontSize: "12px"}} onClick={e=>{
                        cerrarSesion()
                        handleClose(e)
                    }}>
                        <ListItemIcon sx={{minWidth: "20px !important"}}>
                            <Logout fontSize="30px" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
            </Grid>
        </Grid>
           {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton> */}
        </Toolbar>
    )
}

export default ToolbarMui