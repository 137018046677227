import { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import plantillas from '../plantillas';
import React from 'react';
import eventosContext from '../../context/eventos/eventosContext';
import { styled } from '@mui/material';

export default function IndeterminateCheckbox({setData}) {
    const [checked, setChecked] = useState([true, false]);
    const [seleccionado, setSeleccionado] = useState(null);
    const [plantilla, setPlantilla] = useState([]);
    const [checks, setChecks] = useState([]);

    const EventosContext = useContext(eventosContext);
    const { evento, eventos } = EventosContext;

    useEffect(()=>{
        if(evento || evento != ""){
            let data_evento = eventos.filter(ev => ev._id == evento)

            if(data_evento?.length > 0){
                setSeleccionado(data_evento[0])
            }
        }
    },[evento])

    useEffect(()=>{
        let array_props = {}
        if(plantilla?.length > 0){
            plantilla.map(item_plantilla=>{
                let key_name = item_plantilla, name = key_name
                if(item_plantilla.charAt(0) == "*"){
                    let delet_character = name.split("*")
                    key_name = delet_character[1]
                }

                array_props = {
                    ...array_props,
                    [key_name]: false
                }
            })
        }
        if(seleccionado?.apartados){
            let apartados = seleccionado.apartados, name_apartados = Object.keys(apartados);

            if(name_apartados?.length > 0){
                name_apartados.map(name_apartado => {
                    if(name_apartado.charAt(0) == "*"){
                        let item_apartado = apartados[name_apartado]
                        let delet_character = name_apartado.split("*")
                        apartados = {
                            ...apartados,
                            [delet_character[1]]: item_apartado
                        } 
                    }
                })
            }

            array_props = {
                ...apartados
            }
        }

        setChecks(array_props)
    },[plantilla])

    useEffect(()=>{ setData(checks) },[checks])
        
    useEffect(()=>{
        if(seleccionado?.id_plantilla || seleccionado?.id_plantilla == 0){
            let array_plantilla = plantillas({tipo: seleccionado.id_plantilla})
            setPlantilla(array_plantilla)

            plantillas({tipo: seleccionado.id_plantilla})
        }
    },[seleccionado])

    const handleChange1 = (event) => {
        setChecked([event.target.checked, event.target.checked]);
    };

    const handleChange3 = (event) => {
        let name = event.target.name, first_character = name.charAt(0), key_name = name, key_name_character = null;

        if(first_character == "*"){
            let delet_character = name.split("*");
            key_name_character = delet_character[1];
        }

        if(key_name_character){
            setChecks({
                ...checks,
                [key_name]: event.target.checked,
                [key_name_character]: event.target.checked
            });
        }else{
            setChecks({
                ...checks,
                [key_name]: event.target.checked,
            });
        }
    };

    const Container = styled('div')({
        "& label, span":{
            height: "22px",
            fontSize: "12px !important",
            alignContent: "center"
        },
        "& svg":{
            height: "16px"
        }
    })

    const children = (<Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
        {plantilla?.length > 0 ? plantilla.map((prop_plantilla, i_plantilla) => {
            // let array_result_plantillas = prop_plantilla.filter(p_seleccion => p_seleccion.charAt(0) != "*")
            let sub_extract_label = prop_plantilla.split("*")
            let validation_mult = prop_plantilla.split("&")
            let label = ""

            if(sub_extract_label.length > 1){ label = sub_extract_label[1]
            }else{ label = sub_extract_label[0] }

            if(validation_mult?.length > 1){
                return(<Box></Box>)
            }

            return(<FormControlLabel
            label={label}
            key={i_plantilla}
            control={<Checkbox
                key={i_plantilla}
                name={prop_plantilla}
                checked={checks[prop_plantilla]}
                onChange={handleChange3}
            />}
        />)}): null}
    </Box>);

    return(<div>
        <Container>
            <FormControlLabel
                label="Seleccionar todo"
                control={
                <Checkbox
                    checked={true && false}
                    indeterminate={true !== false}
                    onChange={handleChange1}
                />
                }
            />
            {children}
        </Container>
    </div>);
}