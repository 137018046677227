/* eslint-disable import/no-anonymous-default-export */
import { 
    GUARDAR_SECCIONES, OBTENER_SECCIONES
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case GUARDAR_SECCIONES:
            return {
                ...state,
                seccion: action.payload
            }
        case OBTENER_SECCIONES:
            return {
                ...state,
                secciones: action.payload
            }
        default:
            return state
    }
}