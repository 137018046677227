/* eslint-disable import/no-anonymous-default-export */
import { MOSTRAR_ALERTA, OCULTAR_ALERTA } from '../../types'

export default(state, action) => {
    switch(action.type){
        case MOSTRAR_ALERTA:
            return {
                ...state,
                alert: action.payload
            };
        case OCULTAR_ALERTA:
            return {
                ...state,
                alert: null
            };
        default:
            return state;
    }
}