import React, { useContext, useEffect, useState } from 'react';
import itemsContext from '../../../context/items/itemsContext';
import { Ai } from "../../../config/icons"
import NewItem from './NewItem';
import ListItems from './ListItems';
import formItemsContext from '../../../context/formItems/formItemsContext';

const Item = ({zonas, actionFields, elementos}) => {
    const itemContext = useContext(itemsContext);
    const { 
        newItem, 
        mostrarItem, 
        viewItem, 
        view, 
        obtenerItems,
        items
    } = itemContext;
    
    
    const [inspeccion, saveInspeccion] = useState({
        type: "basica"
    });
    
    const [elemento, guardarElemento] = useState([]);
    
    useEffect(()=>{
        if(elementos.length > 0){
            guardarElemento(elementos)
        }
    },[elementos])

    const onClick = () => {
        view(true);
    }

    const ocultar = async (nombre) => {
        mostrarItem(nombre);
        view(false);
        obtenerItems();
    }

    const handleChange = e => {
        saveInspeccion({
            type: e.target.value             
        })
    }

    return (
        <div>
            <div className="typeInspeccion">
                <label>Tipo de Inspección:</label>
                <select id="tipoInspeccion"
                    name="tipoInspeccion"
                    value={inspeccion["type"]}
                    className="type-new-item"
                    onChange={handleChange}
                >
                    <option value="select">Seleccionar...</option>
                    <option value="basica">Básica</option>
                    <option value="avanzada">Avanzada</option>
                </select>
            </div>
            {zonas ? zonas.map((zona, i) => {
                if(inspeccion.type !== zona.inspeccion){
                    if(inspeccion.type === "basica"){
                        return;
                    }
                }
                return (<div className="contenedor-items" key={i}>
                <div className="item">
                    <p><Ai name={zona.icon}/>{zona.nombre}</p>
                    <button type="button" onClick={() => ocultar(zona.nombre)}>
                        {viewItem === zona.nombre ? <Ai name="AiFillCaretUp"/> : <Ai />}
                    </button>
                </div>
                {viewItem === zona.nombre ? 
                        <div className="propiedades">
                            <div className="center">
                        </div>
                        {newItem ? 
                        <NewItem 
                            zona={{id:zona._id, nombre: zona.nombre}} 
                            items={items} 
                            view={view} 
                            actionElements={{elemento: elemento, guardarElemento: guardarElemento}}
                            actionFields={actionFields}
                        />:
                        <ListItems zona={zona.nombre} items={elemento}/>}
                        <div className = "new-property">
                            <button type="button" onClick={onClick} className="btn-new-property">
                                <Ai name="AiFillPlusCircle" />
                            </button>
                        </div>
                    </div> 
                : null}
            </div>)
            })
             : (<p>no hay nada</p>)}
        </div>
    )
}

export default Item;