import React, { useReducer } from 'react';
import eventosReducer from './eventosReducer';
import eventosContext  from './eventosContext';
import clientAxios from '../../config/axios';
import { 
    OBTENER_EVENTOS,
    SELECCIONAR_EVENTO,
    GUARDAR_EVENTO,
    ELIMINAR_EVENTO,
    MODIFICAR_EVENTO
} from '../../types'

const ConversationsState = props => {
    const initialState = {
        eventos: [],
        evento: ""
    }
    const [state, dispatch] = useReducer(eventosReducer, initialState)

    const obtenerEventos = async () => {
        try{
            const res = await clientAxios.get('/api/eventos')
            dispatch({
                type: OBTENER_EVENTOS, 
                payload: res.data.result
            })
        } catch(err){
            console.log(err)
        }
    }

    const eliminarEvento = async id => {
        try{
            await clientAxios.post('/api/eventos/eliminar', {_id: id})
            dispatch({
                type: ELIMINAR_EVENTO, 
                payload: id
            })
        } catch(err){
            console.log(err)
        }
    }

    const seleccionarEvento = id => {
        localStorage.setItem("id_evento", id)
        try{
            dispatch({
                type: SELECCIONAR_EVENTO, 
                payload: id
            })
        } catch(err){
            console.log(err)
        }
    }

    const guardarEvento = async evento => {
        const res = await clientAxios.post('/api/eventos', evento)

        if(res?.data?.code !== 200){
            console.log("ERROR AL GUARDAR EVENTO")
            return false
        }

        try{
            dispatch({
                type: GUARDAR_EVENTO, 
                payload: res.data.msg
            })

            return true
        } catch(err){
            console.log(err)
            return false
        }
    }

    const modificarEvento = async evento => {
        const res = await clientAxios.post('/api/eventos/modificar', evento)

        if(res?.data?.code !== 200){
            console.log("ERROR AL GUARDAR EVENTO")
            return false
        }

        try{
            dispatch({
                type: MODIFICAR_EVENTO, 
                payload: evento
            })

            return true
        } catch(err){
            console.log(err)
            return false
        }
    }
    
    return(
        <eventosContext.Provider value={{
            eventos: state.eventos,
            evento: state.evento,
            obtenerEventos,
            seleccionarEvento,
            guardarEvento,
            eliminarEvento,
            modificarEvento
        }}>
            {props.children}
        </eventosContext.Provider>
    )
}

export default ConversationsState;
