/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect } from 'react';
import { Tabla, Tabla_body, Tabla_col, Tabla_h, Tabla_head, Tabla_row } from '../../Material';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import eventosContext from '../../../context/eventos/eventosContext';
import SolicitudesContext from '../../../context/solicitudes/solicitudesContext';
import { BotonGuardar } from '../../../elements';

const SOLICITUDES = () => {    
    const EventosContext = useContext(eventosContext);
    const { evento } = EventosContext;

    const solicitudesContext = useContext(SolicitudesContext);
    const { solicitudes, obtener_solicitudes, exportar_solicitudes } = solicitudesContext;
    
    useEffect(()=>{
        obtener_solicitudes(evento)
    },[evento])

    useEffect(()=>{
        console.log(solicitudes)
    },[solicitudes])

    return  (<Box sx={{margin: '15px 20px', position: "relative"}}>
        <Grid container sx={{marginBottom: "20px"}}>
            <Grid item xs={6}>
              <Tooltip placement="left-end">
                <Typography sx={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    marginTop: "10px"
                }}>Solicitudes</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={6} sx={{display: "flex", justifyContent: "end", alignSelf: "center"}}>
            </Grid>
        </Grid>
        <Tabla>
            <Tabla_head>
                <Tabla_h>No.</Tabla_h>
                <Tabla_h>Familia</Tabla_h>
                <Tabla_h>Reservados</Tabla_h>
                <Tabla_h align="right">Fecha</Tabla_h>
            </Tabla_head>
            <Tabla_body>{
                solicitudes?.length > 0 ? solicitudes.map((solicitud, i_solicitud) => {
                    if(solicitud?.n_status > 0){
                        return null
                    }
                    return(<Tabla_row key={i_solicitud}>
                        <Tabla_col component="th" scope="row">{i_solicitud+1}</Tabla_col>
                        <Tabla_col component="th" scope="row">{solicitud.s_name}</Tabla_col>
                        <Tabla_col component="th" scope="row">{solicitud.n_reserved}</Tabla_col>
                        <Tabla_col component="th" scope="row">{solicitud.dt_createAt}</Tabla_col>
                    </Tabla_row>)

                }): null
            }</Tabla_body>
        </Tabla>
        <Box sx={{
            position: "absolute",
            top: 10,
            right: 0
        }}>
            <BotonGuardar sx={{
                fontSize: "12px !important"
            }} onClick={e=>{
                if(evento){ exportar_solicitudes(evento) }
            }}>
                Importar Solicitudes
            </BotonGuardar>
            {/* <Modal
                evento={evento}
                titulo="Crear Pase"
                guardarInvitado={guardar_pases}
                usuario={null}
                msgBtn="Crear pase"
                eventos={pases}
            /> */}
        </Box>
    </Box>)
    
}

export default {
    nombre: "Solicitudes",
    template: SOLICITUDES
};