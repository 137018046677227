import { Box, CircularProgress, Grid, Stack, styled } from "@mui/material"
import { Bloque, Titulo } from "../Material"
import TransferList from './TransferList'
import IndeterminateCheckbox from "./CheckList"
import { Save } from "@mui/icons-material"
import { BotonGuardar } from "../../elements"
import { useContext, useState } from "react"
import eventosContext from "../../context/eventos/eventosContext"

const Apartados = () => {
    const EventosContext = useContext(eventosContext);
    const { modificarEvento, evento } = EventosContext;

    const [spin, setSpin] = useState(false)
    const [data, setData] = useState(null)

    const SubTitulo = styled('p')({
        margin: "0px !important",
        marginBottom: "15px !important",
        fontSize: "12px !important"
    })

    const Container = styled('div')({
        position: "sticky",
        top: "0px",
        background: "white",
        zIndex: 2,
        padding: "10px 20px"
    })

    return(<Box>
        <Bloque className="scrollBloque" sx={{padding: "0px"}}>
            <Container>
                <Grid container>
                    <Grid item xs={10} >
                        <Titulo>Apartados</Titulo>
                        <SubTitulo>Puedes <b>Habilitar/Deshabilitar</b> los apartados requeridos en la invitación.</SubTitulo>
                    </Grid>
                    <Grid item xs={2} sx={{
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "end"
                    }}>
                        <BotonGuardar onClick={async e=>{
                            setSpin(true)
                            
                            let result = await modificarEvento({
                                _id: evento,
                                apartados: data
                            })


                            if(result){
                                setSpin(false)
                            }
                        }}>
                            {!spin ?(<>
                                <p>Guardar</p>
                                <Save/>
                            </>):(<>
                                <Box sx={{
                                    display: 'flex',
                                    minWidth: "88px",
                                    justifyContent: "center",
                                    "& span":{
                                        display: "contents"
                                    }
                                }}>
                                    <CircularProgress color="inherit" />
                                </Box>
                            </>)}
                        </BotonGuardar>
                    </Grid>
                </Grid>
            </Container>
            <div style={{margin: "0px 20px 30px 20px"}}>
                <IndeterminateCheckbox setData={setData}/>
            </div>
            {/* <TransferList></TransferList> */}
        </Bloque>
    </Box>)
}

export default Apartados