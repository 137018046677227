/* eslint-disable import/no-anonymous-default-export */
import React from 'react';

export const REPORT = props => {

    return (
        <div className="contenedor-template">
            <div className="template-contenedor">
                <h2>Report<hr/></h2>
            </div>
        </div>
    )
}

export default {
    nombre: "Report",
    template: REPORT
};