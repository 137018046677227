import {
    ChevronLeft,
    ChevronRight,
    Inbox,
    Mail,
    WhatsApp,
    Dashboard,
    QuestionAnswer,
    Facebook,
    Instagram,
    Send,
    MoreVert,
    Liquor,
    EmergencyRecording,
    ViewQuilt,
    Settings,
    LocalActivity
} from '@mui/icons-material';

const Icon = ({name}) =>{
    switch(name){
        case 'ChevronLeft':
            return <ChevronLeft />;
        case 'Apartados':
            return <ViewQuilt/>
        case 'ChevronRight':
            return <ChevronRight />
        case 'Dashboard':
            return <Dashboard />;
        case 'Eventos':
            return <Liquor />;
        case 'Pases':
            return <LocalActivity/>
        case 'Configuraciones':
            return <Settings />;
        case 'Inbox':
            return <Inbox />
        case 'Mail':
            return <Mail />
        case 'Whatsapp':
            return <WhatsApp />
        case 'Facebook':
            return <Facebook/>
        case 'Instagram':
            return <Instagram/>
        case 'Conversations':
            return <QuestionAnswer/>
        case 'Multimedia':
            return <EmergencyRecording/>
        case 'Send':
            return <Send/>
        case 'ThreePoints':
            return <MoreVert/>;
        default:
            return <Inbox />
    }
};

export default Icon;