export const FORMULARIO_MODULO  = 'FORMULARIO_MODULO';
export const OBTENER_MODULOS    = 'OBTENER_MODULOS';
export const AGREGAR_MODULO     = 'AGREGAR_MODULO';
export const VALIDAR_FORMULARIO = 'VALIDAR_FORMULARIO';
export const MODULO_ACTUAL      = 'MODULO_ACTUAL';
export const ELIMINAR_MODULO    = 'ELIMINAR_MODULO';

export const SECCIONES_MODULO   = 'SECCIONES_MODULO';
export const AGREGAR_SECCION    = 'AGREGAR_SECCION';
export const VALIDAR_SECCION    = 'VALIDAR_SECCION';
export const ELIMINAR_SECCION   = 'ELIMINAR_SECCION';
export const ESTADO_SECCION     = 'ESTADO_SECCION';
export const SECCION_ACTUAL     = 'SECCION_ACTUAL';
export const ACTUALIZAR_SECCION = 'ACTUALIZAR_SECCION';
export const LIMPIAR_SECCION    = 'LIMPIAR_SECCION';

export const MOSTRAR_ALERTA = 'MOSTRAR_ALERTA';
export const OCULTAR_ALERTA = 'OCULTAR_ALERTA';

export const REGISTRO_EXITOSO = 'REGISTRO_EXITOSO';
export const REGISTRO_ERROR   = 'REGISTRO_ERROR';
export const OBTENER_USUARIO  = 'OBTENER_USUARIO';
export const LOGIN_EXITOSO    = 'LOGIN_EXITOSO';
export const LOGIN_ERROR      = 'LOGIN_ERROR';
export const CERRAR_SESION    = 'CERRAR_SESION';

export const VALIDAR_AJUSTES  = 'VALIDAR_AJUSTES';
export const MOSTRAR_OPCION  = 'MOSTRAR_OPCION';

export const ESTADO_NUEVO_USUARIO = 'ESTADO_NUEVO_USUARIO';

export const OBTENER_USUARIOS = "OBTENER_USUARIOS";

export const OBTENER_TEMPLATES = "OBTENER_TEMPLATES";

export const OBTENER_FORMATOS = "OBTENER_FORMATOS";
export const SELECCIONAR_FORMATO = "SELECCIONAR_FORMATO";

export const OBTENER_ZONAS = "OBTENER_ZONAS";

export const OBTENER_ITEMS = "OBTENER_ITEMS";
export const MOSTRAR_ITEM = "MOSTRAR_ITEM";
export const OBTENER_VISTAS_ITEMS = "OBTENER_VISTAS_ITEMS";

export const OBTENER_PROPIEDADES = "OBTENER_PROPIEDADES";

// FormItems
export const OBTENER_ITEMS_FORM = "OBTENER_ITEMS_FORM";
export const GUARDAR_ITEMS_FORM = "GUARDAR_ITEMS_FORM";

// Forms
export const OBTENER_FORMS = "OBTENER_FORMS";
export const GUARDAR_FORMS = "GUARDAR_FORMS";
export const SELECT_FORM = "SELECT_FORM";

// Clientes
export const GUARDAR_CLIENTE = "GUARDAR_CLIENTE";
export const OBTENER_CLIENTES = "OBTENER_CLIENTES";
export const OBTENER_CLIENTE = "OBTENER_CLIENTE";

// Inspector
export const GUARDAR_INSPECTOR = "GUARDAR_INSPECTOR";
export const OBTENER_INSPECTOR = "OBTENER_INSPECTOR";
export const OBTENER_INSPECTORES = "OBTENER_INSPECTORES";

// Conversations
export const OBTENER_CONVERSACIONES = "OBTENER_CONVERSACIONES";
export const GUARDAR_CONVERSACIONES = "GUARDAR_CONVERSACIONES";
export const SELECCIONAR_CONTACTO = "SELECCIONAR_CONTACTO";

// Invitados
export const GUARDAR_INVITADO = "GUARDAR_INVITADO";
export const OBTENER_INVITADOS = "OBTENER_INVITADOS";
export const MODIFICAR_INVITADOS = "MODIFICAR_INVITADOS";
export const ELIMINAR_INVITADO = "ELIMINAR_INVITADO";

// Eventos
export const OBTENER_EVENTOS = "OBTENER_EVENTOS";
export const SELECCIONAR_EVENTO = "SELECCIONAR_EVENTO";
export const GUARDAR_EVENTO = "GUARDAR_EVENTO";
export const ELIMINAR_EVENTO = "ELIMINAR_EVENTO";
export const MODIFICAR_EVENTO = "MODIFICAR_EVENTO";

// Multimedia
export const GUARDAR_IMAGEN     = "GUARDAR_IMAGEN";
export const OBTENER_IMAGENES   = "OBTENER_IMAGENES";
export const OBTENER_IMAGEN     = "OBTENER_IMAGEN";
export const ELIMINAR_IMAGEN   = "ELIMINAR_IMAGEN";

// SECCIONES
export const GUARDAR_SECCIONES      = "GUARDAR_SECCIONES";
export const ELIMINAR_APARTADO      = "ELIMINAR_APARTADO";
export const MODIFICAR_APARTADO     = "MODIFICAR_APARTADO";
export const OBTENER_SECCIONES      = "OBTENER_SECCIONES";

// TEMPORALES
export const OBTENER_TEMPORAL           = "OBTENER_TEMPORAL";
export const CREAR_TEMPORAL             = "CREAR_TEMPORAL";
export const OBTENER_TEMPORAL_ID_EVENTO = "OBTENER_TEMPORAL_ID_EVENTO";

// PASES
export const OBTENER_PASES        = "OBTENER_PASES";
export const GUARDAR_PASE        = "GUARDAR_PASE";

// SOLICITUDES
export const OBTENER_SOLICITUDES        = "OBTENER_SOLICITUDES";
export const GUARDAR_SOLICITUDES        = "GUARDAR_SOLICITUDES";
export const EXPORTAR_SOLICITUDES        = "EXPORTAR_SOLICITUDES";
