import * as React from 'react';
import { Box, Table, TableBody,
    TableCell, TableContainer, TableHead,
    TableRow, Paper, Grid, Typography, Modal as ModalMui, styled
} from "@mui/material";
import ModalEvento from './material/Modal'
import { useEffect, useContext, useState } from 'react';
import eventosContext from '../../context/eventos/eventosContext';
import { DeleteForever } from '@mui/icons-material';
import { Boton, Tabla, Tabla_body, Tabla_col, Tabla_h, Tabla_head, Tabla_row, TituloModulo } from '../Material';


const createData = (id, nombre, dtEvento, invitados, _id) => {
  return { id, nombre, dtEvento, invitados, _id };
}


const EVENTOS = () => {
    const [open_confirmation, setOpen_Confirmation] = useState(false)
    const [inv_to_delete, set_inv_to_delete] = useState("")

    const EventosContext = useContext(eventosContext);
    const { eventos, obtenerEventos, eliminarEvento } = EventosContext;
    
    const [rows, setRows] = useState([])

    useEffect(()=>{
        obtenerEventos()
    },[])


    useEffect(()=>{
        let date = "";
        if(eventos.length > 0 && Array.isArray(eventos)){
            let array = [];
            eventos.map((evento, i) => {
                date = new Date(evento.dtEvento)
                let dateFormat =  `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`
                array = [
                    ...array,
                    createData(
                        i+1,
                        evento.nombre, 
                        dateFormat, 
                        evento.invitados, 
                        evento._id
                    )
                ]
            })
            setRows(array)
        }
    },[eventos])

    return(<Box sx={{margin: '15px 20px'}}>
        <Grid container sx={{marginBottom: "10px"}}>
            <Grid item xs={6}>
                <TituloModulo>Eventos</TituloModulo> 
            </Grid>
            <Grid item xs={6} sx={{alignSelf: "center"}}>
                <ModalEvento
                    titulo="Nuevo Evento"
                    // guardarInvitado={guardarInvitado}
                    // usuario={usuario}
                    msgBtn="Agregar Evento Nuevo"
                />
            </Grid>
        </Grid>
        <Tabla>
            <Tabla_head>
                <Tabla_h>id</Tabla_h>
                <Tabla_h align="right">Nombre</Tabla_h>
                <Tabla_h align="right">Fecha</Tabla_h>
                <Tabla_h align="right">Numero de Invitados</Tabla_h>
                <Tabla_h align="right">Acción</Tabla_h>
            </Tabla_head>
            <Tabla_body>
                {rows.toReversed().map((row) => (
                    <Tabla_row key={row.id}>
                        <Tabla_col component="th" scope="row">{row.id}</Tabla_col>
                        <Tabla_col align="right">{row.nombre}</Tabla_col>
                        <Tabla_col align="right">{row.dtEvento}</Tabla_col>
                        <Tabla_col align="right">{row.invitados}</Tabla_col>
                        <Tabla_col align="right">
                            <DeleteForever sx={{cursor: "pointer"}} onClick={()=>{
                                setOpen_Confirmation(true)
                                set_inv_to_delete(row._id)
                                // eliminarEvento(row._id)
                            }}/>
                        </Tabla_col>
                    </Tabla_row>
                ))}
            </Tabla_body>
        </Tabla>
        <ModalMui
            open={open_confirmation}
            onClose={()=>setOpen_Confirmation(false)}
            aria-labelledby="ModalMui-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                <Typography sx={{fontSize: "18px"}}>¿Desea <b>Eliminar</b> el Evento?</Typography>
                <Typography sx={{fontSize: "12px"}}>
                    Al eliminar el evento se eliminara permanentemente <b>¿Desea continuar?</b>
                </Typography>
                <Box sx={{display: "grid", justifyContent: "end", marginTop: "20px"}}>
                    <Box sx={{display: "flex"}}>
                        <Boton onClick={()=> {
                            setOpen_Confirmation(false)
                            set_inv_to_delete("")
                        }}>Cancelar</Boton>
                        <Boton onClick={()=>{
                            if(inv_to_delete){eliminarEvento(inv_to_delete)}
                            setOpen_Confirmation(false)
                            set_inv_to_delete("")
                        }} sx={{marginLeft:"10px"}}>Aceptar</Boton>
                    </Box>
                </Box>
            </Box>
        </ModalMui>
    </Box>)
}


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default {
    nombre: "Eventos",
    template: EVENTOS
};