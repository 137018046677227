/* eslint-disable import/no-anonymous-default-export */
import { 
    GUARDAR_INVITADO,
    OBTENER_INVITADOS,
    ELIMINAR_INVITADO,
    MODIFICAR_INVITADOS
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case GUARDAR_INVITADO:
            console.log(action.payload)
            return {
                ...state,
                invitados: [...state.invitados, action.payload]
            }
        case ELIMINAR_INVITADO:
            return {
                ...state,
                invitados: action.payload
            }
        case OBTENER_INVITADOS:
            return {
                ...state,
                invitados: action.payload
            }
        case MODIFICAR_INVITADOS:
            console.log(action.payload)
            return {
                ...state
            }
        default:
            return state
    }
}