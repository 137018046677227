/* eslint-disable import/no-anonymous-default-export */
import {
    GUARDAR_PASE,
    OBTENER_PASES
} from '../../types'

export default (state, action) => {
    switch(action.type){
        case OBTENER_PASES:
            return {
                ...state,
                pases: action.payload
            }
        case GUARDAR_PASE:
            return {
                ...state,
                pases: [
                    ...state.pases,
                    action.payload
                ]
            }
        default:
            return state;
    }
}