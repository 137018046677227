/* eslint-disable react/jsx-pascal-case */
import TEST from "./src/testTemplate";
import REPORT from "./src/reportTemplate";
import ZONAS from "./src/zonasTemplate";
import ELEMENTOS from "./src/elementosTemplate";
import COMENTARIOS from "./src/comentariosTemplate";
import FORMULARIO from "./src/formularioTemplate";
import GESTION from "./src/gestionTemplate";
import EDITARFORM from "./src/editarFormTemplate";
import DASHBOARD from "./src/dashboardTemplate";
import INVITACIONES from "./src/invitacionesTemplate";
import EVENTOS from "../eventos";
import MULTIMEDIA from "./src/multimediaTemplate";
import APARTADOS from "./src/apartadosTemplate";
import CONFIGURACIONES from "./src/configuracionesTemplate";
import PASES from "./src/pasesTemplate";
import SOLICITUDES from "./src/solicitudesTemplate";

const Componente = ({nombre}) => {
    switch(nombre){
        case COMENTARIOS.nombre:
            return (<COMENTARIOS.template />);
        case GESTION.nombre:
            return (<GESTION.template />);
        case ELEMENTOS.nombre:
            return (<ELEMENTOS.template />);
        case FORMULARIO.nombre:
            return (<FORMULARIO.template />);
        case REPORT.nombre:
            return (<REPORT.template />);
        case TEST.nombre:
            return (<TEST.template />);
        case DASHBOARD.nombre:
            return (<DASHBOARD.template />);
        case ZONAS.nombre:
            return (<ZONAS.template />);
        case EDITARFORM.nombre:
            return (<EDITARFORM.template />);
        case INVITACIONES.nombre:
            return (<INVITACIONES.template />);
        case EVENTOS.nombre:
            return (<EVENTOS.template />);
        case MULTIMEDIA.nombre:
            return (<MULTIMEDIA.template />);
        case APARTADOS.nombre:
            return (<APARTADOS.template />);
        case CONFIGURACIONES.nombre:
            return (<CONFIGURACIONES.template />);
        case PASES.nombre:
            return (<PASES.template />);
        case SOLICITUDES.nombre:
            return (<SOLICITUDES.template />);
        default:
            return (<div>
                <p>Plantilla por default</p>
            </div>);
    }
}

export default Componente;