/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import MultimediaComponente from '../../multimedia';

const MULTIMEDIA = () => {
    return (<MultimediaComponente/>)
}

export default {
    nombre: "Multimedia",
    template: MULTIMEDIA
};