/* eslint-disable import/no-anonymous-default-export */
import { 
    OBTENER_EVENTOS,
    SELECCIONAR_EVENTO,
    GUARDAR_EVENTO,
    ELIMINAR_EVENTO,
    MODIFICAR_EVENTO
} from "../../types"

export default (state, action) => {
    switch(action.type){
        case OBTENER_EVENTOS:
            return {
                ...state,
                eventos: action.payload
            }
        case SELECCIONAR_EVENTO:
            return {
                ...state,
                evento: action.payload
            }
        case GUARDAR_EVENTO:
            return {
                ...state,
                eventos: [...state.eventos, action.payload]
            }
        case ELIMINAR_EVENTO:
            let old_events = state.eventos,
            new_events = old_events.filter(old_event => old_event._id !== action.payload)
            return {
                ...state,
                eventos: new_events
            }
        case MODIFICAR_EVENTO:
            let events = state.eventos, event = action.payload
            // let res_events = events.filter(evs => evs._id != event._id)
            let res_event = events.filter(evs => evs._id === event._id)

            if(res_event?.length > 0){
                let result_evs = events.map(ev=>{
                    if(ev._id === event._id){
                        return {
                            ...ev,
                            ...event
                        }
                    }else{
                        return ev
                    }
                })

                return {
                    ...state,
                    eventos: result_evs
                }
            }else{
                return {...state, eventos: state.eventos}
            }
        default:
            return state
    }
}