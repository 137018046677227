import { Box, Grid, styled } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { Texto } from "../Material"
import multimediaContext from "../../context/multimedia/multimediaContext"
import eventosContext from "../../context/eventos/eventosContext"
import { CheckCircleOutline, Close } from "@mui/icons-material"
import seccionesContext from "../../context/secciones/seccionesContext"
import plantillas from "../plantillas"


const Titulo = styled('p')({
    fontSize: "20px",
    fontWeight: "500",
    padding: "0px",
    margin: "0px"
})

const Bloque = styled('div')({
    margin: "20px 20px",
    background: "white",
    borderRadius: "8px",
    padding: "10px 20px",
    overflowY: "scroll",
    maxHeight: "555px !important"
})

const InputFiles = ({name, setFiles, files = [], guardarImagen}) => {
    const EventosContext = useContext(eventosContext);
    const { evento } = EventosContext;

    const InputDiv = styled('div')({
        justifyContent: "start",
        alignItems: "center",
        display: "flex",
        width: "100%"
    })

    const InputLabel = styled('p')({
        "--tw-bg-opacity": 1,
        backgroundColor: "rgb(249 250 251/var(--tw-bg-opacity))",
        "--tw-border-opacity": 1,
        borderColor: "rgb(209 213 219/var(--tw-border-opacity))",
        borderStyle: "dashed",
        borderWidth: "2px",
        borderRadius: "0.5rem",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        cursor: "pointer",
        textAlign: "center",
        width: "90%",
        height: "125px",
        display: "flex"
    })

    const DivInputs = styled('div')({
        padding: "1rem 0px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        position: "relative"
    })

    const ImageFileInput = styled('svg')({
        "--tw-text-opacity": 1,
        color: "rgb(107 114 128/var(--tw-text-opacity)) !important",
        width: "2rem",
    })

    const TextoFileInput = styled('p')({
        "--tw-text-opacity": 1,
        color: "rgb(107 114 128/var(--tw-text-opacity))",
        padding: "0px",
        margin: "0px",
    })

    const InputInfo = styled('input')({
        cursor: "pointer",
        opacity: 0.0,

        /* IE 8 */
        "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)",

        /* IE 5-7 */
        filter: "alpha(opacity=0)",
        
        /* Netscape or and older firefox browsers */
        "-moz-opacity": 0.0,

        /* older Safari browsers */
        "-khtml-opacity": 0.0,

        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height:"100%"
    })

    const MultimediaFn = async e => {
        let filesInput = e.target.files, nombre_file = `${evento}/${name}`
        if(!evento || !name || filesInput.length == 0) return console.log("Error al subir imagen")

        let array = {
            ...files,
            [name]: await filesInput
        }

        guardarImagen(filesInput, nombre_file)
        setFiles({...array})
    }

    return(<InputDiv>
        <InputLabel for="dropzone-file">
            <DivInputs>
                <ImageFileInput aria-hidden="true" xmlns="http://www.w3.org/2000/ImageFileInput" fill="none" viewBox="0 0 20 16">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                </ImageFileInput>
                <TextoFileInput class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span class="font-semibold">Click para subir</span> o arrastrar
                </TextoFileInput>
                <TextoFileInput class="text-xs text-gray-500 dark:text-gray-400">PNG o JPG</TextoFileInput>
                <InputInfo onChange={MultimediaFn} type="file" class="hidden" />
            </DivInputs>
        </InputLabel>
    </InputDiv>)
}

const Multimedia = () => {
    const [files, setFiles] = useState({})
    const [secciones_, setSecciones] = useState([])
    const [seleccionado, setSeleccionado] = useState(null)
    const [tipo, setTipo] = useState(0)
    const [plantilla, setPlantilla] = useState([])

    const SeccionesContext = useContext(seccionesContext);
    const { obtenerSecciones, secciones } = SeccionesContext;

    const MultimediaContext = useContext(multimediaContext);
    const { guardarImagen, obtenerListadoImagenes, listado, eliminarImagen } = MultimediaContext;

    const EventosContext = useContext(eventosContext);
    const { evento, eventos } = EventosContext;

    useEffect(()=>{
        obtenerSecciones(evento)
        obtenerListadoImagenes(evento)
    },[])

    useEffect(()=>{
        obtenerSecciones(evento)
        obtenerListadoImagenes(evento)

        if(evento || evento != ""){
            let data_evento = eventos.filter(ev => ev._id == evento)

            if(data_evento?.length > 0){
                setSeleccionado(data_evento[0])
            }
        }
    },[evento])

    useEffect(async ()=>{
        setSecciones([])
        let secciones_habiles = await secciones.right
        if(secciones_habiles?.length > 0){
            setSecciones([...secciones_habiles])
        }
    },[secciones])

    useEffect(()=>{
        if(seleccionado?.id_plantilla || seleccionado?.id_plantilla == 0){
            setTipo(seleccionado.id_plantilla)
        }
    },[seleccionado])

    useEffect(()=>{
        const plantilla_seleccionada = plantillas({tipo: tipo})
        if(plantilla_seleccionada && plantilla_seleccionada?.length >0){
            let array_result_plantillas = plantilla_seleccionada.filter(p_seleccion => p_seleccion.charAt(0) != "*")
            
            setPlantilla(array_result_plantillas)
        }
    },[tipo])

    useEffect(()=>{
        setFiles({})
        if(listado?.length > 0){
            let files_ = {};
            listado.map((item, i_item) => {
                let key_name = item.Key, suf = key_name.split("/")
                let name_file = suf[1], suf_name = name_file.split(".")

                files_ = {
                    ...files_,
                    [suf_name[0]]: [{name: name_file}]
                }

            })
            setFiles({...files_})
        }
    },[listado])

    useEffect(()=>{
        console.log(files)
    },[files])

    const ContenedorFile = styled('div')({
        width: "50%"
    })

    const FileCharge = styled('div')({
        display: "flex",
        background: "#eef2f6",
        padding: "0px 15px",
        borderRadius: "0px 0px 8px 8px",
        border: "1px solid #e7e7e7"
    })

    return(<Box>
        <Bloque className="scrollBloque">
            <Titulo>Multimedia</Titulo>
            <Grid container>            
                {plantilla?.length > 0 ? plantilla.map(item => {
                    if(files[item]?.length > 0){                        
                        return(<Grid item xs={4}>
                            <Texto sx={{
                                fontSize: "16px",
                                fontWeight: "500",
                                margin: "0px",
                                marginTop: "15px",
                                textTransform: "uppercase",
                                borderBottom: "1px solid #cfcfcf",
                                background: "#cfe1f3",
                                borderRadius: "8px 8px 0px 0px",
                                padding: "5px 10px",
                                marginRight: "20px"
                            }}>{item}</Texto>
                            <Box sx={{margin: "0px 21px 20px 0px"}}>
                            <FileCharge>
                                <ContenedorFile sx={{display: "flex"}}>
                                    <CheckCircleOutline sx={{
                                        alignSelf: "center",
                                        marginRight: "10px",
                                        fontSize: "18px",
                                        color: "green !important"
                                    }}/>
                                    <p>{item}</p>
                                </ContenedorFile>
                                <ContenedorFile onClick={()=> {
                                    let file_to_delete = files[item]
                                    console.log(file_to_delete)
                                    eliminarImagen(file_to_delete[0].name, evento)                                
                                    setFiles({
                                        ...files,
                                        [item]: []
                                    })

                                }} sx={{display: "grid", justifyContent: "end", cursor: "pointer"}}>
                                    <Close sx={{
                                        alignSelf: "center",
                                        fontSize: "18px"
                                    }}/>
                                </ContenedorFile>
                            </FileCharge>
                        </Box>
                        </Grid>)
                    }else{
                        return(
                            <Grid item xs={4}>
                                <Texto sx={{
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    margin: "0px",
                                    marginTop: "15px",
                                    textTransform: "uppercase",
                                    borderBottom: "1px solid #cfcfcf",
                                    background: "#eef2f6",
                                    borderRadius: "8px",
                                    padding: "5px 10px",
                                    marginRight: "20px"
                                }}>{item}</Texto>
                                <InputFiles
                                    files={files}
                                    name={item}
                                    setFiles={setFiles}
                                    guardarImagen={guardarImagen}
                                />
                            </Grid>)
                    }
                }): null}
            </Grid>
        </Bloque>
    </Box>)
}

export default Multimedia
