import { styled } from "@mui/material";

export const BotonGuardar = styled('div')({
    "& p":{
        fontSize: "12px",
        width: "fit-content",
        color: "white"
    },
    "& svg, path":{
        height:"16px",
        color: "white !important"
    },
    display: "flex",
    alignItems: "center",
    background: "#2da6ff",
    color: "white !important",
    padding: "0px 10px",
    height: "30px",
    borderRadius: "8px",
    cursor: "pointer",
    boxShadow: "0px 3px 5px -3px #797979"
})