import * as React from 'react';
import {
    InputLabel,
    MenuItem,
    FormControl,
    Box
} from '@mui/material';
import { styled } from '@mui/material/styles'
import MuiSelect from '@mui/material/Select'

const Select = styled(MuiSelect)(({theme}) => ({
  border: 0,
  background: 'rgba(255, 255, 255, 0.04)'
}))

const SelectAutoWidth = () =>{
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };


  return (
    <div id="field-market" style={{width: '90%', paddingLeft: '10%', paddingRight: '3%'}} >
      <Box sx={{border: 0}}>
        <FormControl sx={{ m: 1, width: '100%',border: 0 }}>
          <InputLabel sx={{border: 0, color: 'white'}} id="field-market-label">Market</InputLabel>
          <Select
            labelId="field-market-label"
            value={age}
            onChange={handleChange}
            label="Age"
          >
            <MenuItem sx={{ border: 0 }} value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Twenty</MenuItem>
            <MenuItem value={21}>Twenty one</MenuItem>
            <MenuItem value={22}>Twenty one and a half</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}




export default SelectAutoWidth;