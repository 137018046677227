import React from 'react';
import { Ai } from "../../../config/icons"

const ListItems = ({items, zona}) => {
    const editAction = async () =>{
        console.log("edit")
    }

    const deleteAction = async () =>{
        console.log("deleted")
    }

    return (
        <div className="list-item-conetenedor">
            {items.length !== 0 ? items.map((item, i) =>{
                if(item.zona.nombre === zona){
                    return(
                        <div className="row-template" key={i}>
                            <div className="col-3-4">
                                <label>{item.name}</label>
                            </div>
                            <div className="col-1-4 right">
                                <div className="icon-button-new-item">
                                    <button type="button" onClick={editAction} className="btn btn-blank"><Ai name="AiTwotoneEdit"></Ai></button>
                                    <button type="button" onClick={deleteAction} className="btn btn-blank"><Ai name="AiFillDelete"></Ai></button>
                                </div>
                            </div>
                        </div>
                    )
                }
            }) : null}
        </div>
    )

    
}

export default ListItems;