import React, {useContext, useEffect, useState} from 'react';
import alertsContext from '../../context/alerts/alertsContext';
import AuthContext from '../../context/authentication/authContext';
import {Link} from "react-router-dom";
import {
    TextField,
    InputAdornment, 
    IconButton,
    FormControl,
    OutlinedInput,
    InputLabel,
    Alert,
    Stack,
    Box,
    Container,
    Button,
    Card
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material'
import imgUrl from '../../img/RL-banner-white.png'
 
const Login = props => {
    // Extraer los valores del context
    const alertContext = useContext(alertsContext);
    const { alert, mostrarAlerta } = alertContext;

    //  Extraer los valores del autenticacion
    const authContext = useContext(AuthContext);
    const { mensaje, login, autenticado } = authContext;

    useEffect(()=>{
        if(autenticado){
            props.history.push('/modules')
        }

        if(mensaje){
            mostrarAlerta(mensaje.msg, 'alerta-error')
            return;
        }
    }, [mensaje, autenticado, props.history])

    // State para iniciar sesión
    const [user, saveUser] = useState({
        email:'',
        password:'',
        showPassword: false,
    })

    const {email, password} = user;

    const onChange = e => {
        saveUser({
            ...user,
            [e.target.name] : e.target.value
        })
    }

    const handleClickShowPassword = () => {
        saveUser({
          ...user,
          showPassword: !user.showPassword,
        });
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };
    
    const onSubmit = e =>{
        e.preventDefault();
        // Validar que no haya campos vacios
        if(email.trim() === '' || password.trim() === ''){
            mostrarAlerta(
                `${email.trim() === '' ? `El usuario ${password.trim() === '' ? "y la contraseña son campos requeridos." : " es requerido."}` : 
                    password.trim() === '' ? "La contraseña es requerida." : ""
                }
                `, 
            "alerta-error")

            return;
        }

        login({ email, password });
    }

    return(
        <div className="bg-dark-body full-display">
            <Container className="bg-dark-body" sx={{height: "100vh", display: 'grid', alignContent: "center"}} maxWidth="sm" fixed>
                <Card className='bg-dark-container shadow' sx={{padding: '5%'}}>
                    <div className="contenedor-form sombra-dark">
                        <div className="field-logo">
                            <img className="logo-login" src={imgUrl}/>
                        </div>
                        <form onSubmit={onSubmit}>
                            <div className="campo-form field-info">
                                <TextField
                                    className='field-login' 
                                    id="email"
                                    type="email"
                                    name="email"
                                    label="Email"
                                    value={email}
                                    onChange={onChange}
                                    variant="outlined" fullWidth/>
                            </div>
                            <div className="campo-form field-info">
                                <FormControl variant="outlined" fullWidth>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    name="password"
                                    type={user.showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={onChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {user.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                fullWidth/>
                                </FormControl>
                            </div>

                        <div className="campo-form">
                            <Button sx={{
                                background: '#2da6ff', 
                                padding:'10px 0px',
                                color:'white',
                                textTransform: "none",
                                fontFamily:`Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';`
                            }} type="submit" variant="contained" fullWidth>Iniciar Sesión</Button>
                        </div>
                    </form>
                    {alert ? 
                        (
                            <Stack sx={{ width: '100%', paddingTop: '20px' }} spacing={2}>
                                <Alert className={`dark ${alert.category}`} severity="error">{alert.msg}</Alert>
                            </Stack>
                        )
                    : null}
                    {/* <hr className="line-divider"></hr>
                    <div className='field-other-options'>
                        <Box className="link-text">
                            <Link to={'/new-account'} className="enlace-cuenta">
                                Crear cuenta nueva
                            </Link>
                        </Box>
                        <Box className="link-text">
                            <Link to={'/new-account'} className="enlace-cuenta">
                                ¿Olvidaste la contraseña?
                            </Link>
                        </Box>
                    </div> */}
                    </div>
                </Card>
            </Container>
        </div>
    )
}

export default Login;