/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from 'react';
import { Tabla, Tabla_body, Tabla_col, Tabla_h, Tabla_head, Tabla_row } from '../../Material';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import PasesContext from '../../../context/pases/pasesContext';
import eventosContext from '../../../context/eventos/eventosContext';
import Modal from '../modales/pases'
import { Link, MoreVert } from '@mui/icons-material';

const PASES = ({}) => {
    const pasesContext = useContext(PasesContext);
    const { obtener_pases, pases, guardar_pases } = pasesContext;

    const EventosContext = useContext(eventosContext);
    const { evento } = EventosContext;
    
    useEffect(()=>{
        obtener_pases(evento)
    },[evento])

    useEffect(()=>{
        console.log(pases)
    },[pases])

    const copiarLink = (code) => {
        var aux = document.createElement("input");
        aux.setAttribute("value", `${process.env.REACT_APP_FRONT_URL_HOME}pass/temporal/${code}`);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
    }

    return  (<Box sx={{margin: '15px 20px', position: "relative"}}>
        <Grid container sx={{marginBottom: "20px"}}>
            <Grid item xs={6}>
              <Tooltip placement="left-end">
                <Typography sx={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    marginTop: "10px"
                }}>Pases Temporales</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={6} sx={{display: "flex", justifyContent: "end", alignSelf: "center"}}>
            </Grid>
        </Grid>
        <Tabla>
            <Tabla_head>
                <Tabla_h>No.</Tabla_h>
                <Tabla_h>código</Tabla_h>
                <Tabla_h>url</Tabla_h>
                <Tabla_h>Estatus</Tabla_h>
                <Tabla_h align="right">Fecha</Tabla_h>
            </Tabla_head>
            <Tabla_body>{
                pases?.length > 0 ? pases.map((pase, i_pase) => (<Tabla_row key={i_pase}>
                    <Tabla_col component="th" scope="row">{i_pase+1}</Tabla_col>
                    <Tabla_col component="th" scope="row">{pase.n_codigo}</Tabla_col>
                    <Tabla_col component="th" scope="row">
                        <Link
                            sx={{cursor: "pointer", "&:hover": {color:"gold !important"}}}
                            onClick={e => copiarLink(pase.n_codigo)}
                        />
                        {/* {pase.n_codigo} */}
                    </Tabla_col>
                    <Tabla_col component="th" scope="row">{pase.i_status}</Tabla_col>
                    <Tabla_col component="th" scope="row">{pase.createAt}</Tabla_col>
                </Tabla_row>)): null
            }</Tabla_body>
        </Tabla>
        <Box sx={{
            position: "absolute",
            top: 10,
            right: 0
        }}>
            <Modal
                evento={evento}
                titulo="Crear Pase"
                guardarInvitado={guardar_pases}
                usuario={null}
                msgBtn="Crear pase"
                eventos={pases}
            />
        </Box>
    </Box>)
    
}

export default {
    nombre: "Pases",
    template: PASES
};