import { styled } from "@mui/material";

export const Titulo = styled('p')({
    fontSize: "20px",
    fontWeight: "500",
    padding: "0px",
    margin: "0px"
})

export const Boton = styled('button')({
    background: "#403000 !important",
      color: "rgb(255, 255, 255) !important",
      textTransform: "none !important",
      cursor: "pointer",
      border: "none",
      borderRadius: "5px",
      padding: "7px 18px",
      outline: "none !important",
      fontSize: "12px",
      "&:hover":{
          backgroundColor: "#d7b615 !important",
          color: "white !important",
          textShadow: "0px 5px 10px #ffeb77"
      }
})

export const TituloModulo = styled('p')({
    fontSize: "20px",
    fontWeight: "400",
    color: "#595959",
    textShadow: "0px 1px 2px gray"
})

export const Tabla          = styled('table')({
    background: "white",
    width: "100%",
    boxShadow: "0px 1px 7px -4px gray",
    borderRadius: "5px",
    border: "1px solid #a1a1a1",
})
export const Tabla_head     = styled('thead')({
    background: "#e9e9e9",
    borderCollapse: "collapse",
})
export const Tabla_body     = styled('tbody')({})
export const Tabla_row      = styled('tr')({})
export const Tabla_col      = styled('td')({
    textAlign: "start",
    padding: "0px 5px",
    fontSize: "12px"
})
export const Tabla_h        = styled('th')({
    textAlign: "start",
    padding: "5px",
    fontSize: "12px"
})

export const Boton_Modulo      = styled('li')({
    display: "flex",
    alignItems: "center",
    paddingLeft: "15px",
    height: "30px",
    cursor: "pointer",
    width: "100%",
    "& svg": {
        width: "15px"
    },
    "&:hover":{
        background: "#efefff"
    }
})
export const Texto_Modulo      = styled('p')({
    fontSize: "12px"
})

export const Separador_Modulo      = styled('p')({
    fontSize: "13px",
    margin: "0px",
    color: "#7d7d7d",
    fontWeight: "500",
    paddingBottom: "2px",
    paddingLeft: "15px",
    borderBottom: "1px solid #d9d9d9",
    marginTop: "15px"
})

export const Buscador      = styled('input')({
})

export const SelectorEvento = styled('div')({
    cursor: "pointer",
    width: "fit-content",
    borderRadius: "30px",
    fontSize: "12px",
    background: "#f1f1f1",
    border: "1px solid #dfdfdf",
    height: "26px",
    paddingLeft: "10px",
    margin: "5px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "end"
})

export const Texto = styled('p')({
    fontSize: "12px"
})

export const Lista = styled('ul')({
    padding: "0px",
    fontSize: "12px",
    overflowY: "scroll",
    height: "120px",
    minHeight: "120px",
    maxHeight: "120px",
    cursor: "pointer",
    borderBottom: "1px solid #e1e1e1"
})

export const ListaBoton = styled('li')({
    listStyle: "none",
    padding: "5px 0px",
    paddingLeft: "30px",
    "&:hover":{
        background: "#f1f1f1"
    }
})

export const Bloque = styled('div')({
    margin: "20px 20px",
    background: "white",
    borderRadius: "8px",
    padding: "10px 20px",
    overflowY: "scroll",
    maxHeight: "555px !important"
})

export const Campo = styled('input')({
    width: "100%",
    padding: "7px 14px",
    fontSize: "12px",
    borderRadius: "5px"
})