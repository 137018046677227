/* eslint-disable import/no-anonymous-default-export */
import React from 'react';

const TEST = () => {
    return  (
        <>
            <h1>Test</h1>
        </>)
    
}

export default {
    nombre: "Test",
    template: TEST
};