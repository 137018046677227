import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Login from './components/auth/Login';
import NewAccount from './components/auth/NewAccount';
import Modules from './components/modules/Modules';
import AlertState from './context/alerts/alertState';
import AuthState from './context/authentication/authState';

import ModuleState from './context/modules/moduleState'
import SectionState from './context/sections/sectionState';

import tokenAuth from './config/tokenAuth';
// validar sesion con ruta privada
import RoutePrivate from './components/routes/routePrivate';
import SettingsState from './context/settings/settingsState';

import NewUserState from './context/newUsers/newUserState';
import UsersState from './context/users/usersState';
import TemplateState from './context/templates/templateState';

import PropertyState from './context/properties/propertyState';
import FormsState from './context/forms/formsState';

import GuestsState from './context/guests/guestsState';

import EventosState from './context/eventos/eventosState';
import MultimediaState from './context/multimedia/multimediaState';
import SeccionesState from './context/secciones/seccionesState';
import Guests from './components/guests';
import TempporalState from './context/temporal/temporalState';
import PasesState from './context/pases/pasesState';
import SolicitudesState from './context/solicitudes/solicitudesState';

const token = localStorage.getItem('token');
if(token){
  tokenAuth(token);
}

function App() {
  return (
    <ModuleState>
      <MultimediaState>
        <SolicitudesState>
          <PasesState>
            <TempporalState>
              <SeccionesState>
                <EventosState>
                  <SectionState>
                    <AlertState>
                      <AuthState>
                        <NewUserState>
                          <UsersState>
                            <Router>
                              <Switch>
                                <SettingsState>
                                  <TemplateState>
                                    <PropertyState>
                                        <GuestsState>
                                          <FormsState>
                                            <Route exact path="/pass/temporal/:code_guest" component={Guests}/>
                                            <Route exact path="/" component={Login}/>
                                            <Route exact path="/new-account" component={NewAccount}/>
                                            <RoutePrivate exact path="/modules" component={Modules}/>
                                          </FormsState>
                                        </GuestsState>
                                    </PropertyState>
                                  </TemplateState>
                                </SettingsState>
                              </Switch>
                            </Router>
                          </UsersState>
                        </NewUserState>
                      </AuthState>
                    </AlertState>
                  </SectionState>
                </EventosState>
              </SeccionesState>
            </TempporalState>
          </PasesState>
        </SolicitudesState>
      </MultimediaState>
    </ModuleState>
  );
}

export default App;
